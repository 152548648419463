<template>

  <project-table />

</template>

<script>

import ProjectTable from '@/views/project/project-list/ProjectTable.vue'

export default {
  components: {
    ProjectTable,

  },
  setup() {
    return {

    }
  },
}
</script>
