<template>
  <b-card
    no-body
    class="mb-0 mt-1"
  >
    <div class="m-2">
      <!-- Table Top -->
      <b-row>
        <!-- Per Page -->
        <b-col
          cols="12"
          md="6"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >
          <label>Show</label>
          <v-select
            v-model="perPage"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="perPageOptions"
            :clearable="false"
            class="per-page-selector d-inline-block mx-50"
          />
          <label>entries</label>
        </b-col>

        <!-- Add Item -->
        <b-col
          cols="12"
          md="6"
        >
          <div class="d-flex align-items-center justify-content-end">
            <b-form-input
              v-model="searchQuery"
              class="d-inline-block mr-1"
              placeholder="Search..."
            />
          </div>
        </b-col>
      </b-row>
    </div>

    <b-table
      ref="refProjectsListTable"
      class="position-relative mh-200"
      :items="fetchProjects"
      responsive
      :fields="tableColumns"
      primary-key="id"
      :sort-by.sync="sortBy"
      show-empty
      empty-text="Nessun elemento trovato"
      :sort-desc.sync="isSortDirDesc"
    >

      <!-- Column: Code -->

      <template #cell(name)="data">
        <div class="text-nowrap">
          <span class="align-text-top text-bold">{{ data.item.name }}</span>
        </div>
      </template>

      <template #cell(rule)="data">
        <div
          class="text-nowrap"
        >
          <b-badge
            v-if="data.item.rule"
            variant="success"
            class="badge"
          >
            {{ data.item.rule.name }}
          </b-badge>
          <b-badge
            v-else
            variant="warning"
            class="badge"
          >
            Da assegnare
          </b-badge>
        </div>
      </template>

      <template #cell(expiration)="data">
        <div class="text-nowrap">
          <span class="align-text-top text-bold">{{ data.item.expiration }}</span>
        </div>
      </template>

      <template #cell(created_at)="data">
        <div class="text-nowrap">
          <span class="align-text-top text-bold">{{ data.item.created_at }}</span>
        </div>
      </template>

      <template #cell(updated_at)="data">
        <div class="text-nowrap">
          <span class="align-text-top text-bold">{{ data.item.updated_at }}</span>
        </div>
      </template>

      <!-- Column: Actions -->
      <template #cell(azioni)="data">

        <div class="text-nowrap">
          <feather-icon
            :id="`invoice-row-${data.item.id}-send-icon`"
            icon="EyeIcon"
            class="cursor-pointer"
            size="16"
            @click="$router.push({ name: 'progetto-show', params: { progetto: data.item.id } })"
          />
          <b-tooltip
            title="Vedi"
            class="cursor-pointer"
            :target="`invoice-row-${data.item.id}-send-icon`"
          />
        </div>

      </template>

    </b-table>
    <div class="mx-2 mb-2">
      <b-row>

        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-start"
        >
          <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
        </b-col>
        <!-- Pagination -->
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >

          <b-pagination
            v-model="currentPage"
            :total-rows="totalElements"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>

        </b-col>

      </b-row>
    </div>
  </b-card>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BTable,
  BPagination,
  BFormInput,
  BBadge,
  BTooltip,
  // BLink,
} from 'bootstrap-vue'

import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import useProject from '@/views/project/useProject'

// Notification

export default {
  name: 'ProjectTable',
  components: {
    BCard,
    BRow,
    BCol,
    BTable,
    BPagination,
    BFormInput,
    BBadge,
    BTooltip,
    vSelect,
  },
  directives: {
    Ripple,
  },
  setup() {
    const {
      refProjectsListTable,
      tableColumns,
      perPage,
      totalElements,
      currentPage,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      dataMeta,
      fetchProjects,
      refetchData,
      getListProject,
      projectSecondMenu,
      projectMenu,
      resolveLabelColor,
    } = useProject()

    return {
      refProjectsListTable,
      tableColumns,
      perPage,
      totalElements,
      currentPage,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      dataMeta,
      fetchProjects,
      refetchData,
      getListProject,
      projectSecondMenu,
      projectMenu,
      resolveLabelColor,
    }
  },
}
</script>

<style lang="scss">
    @import '@core/scss/vue/libs/vue-select.scss';
</style>

<style scoped/>
